<template>
  <div class="page-back">
    <NavBar class="nav-bar">
      <div slot="title">用户评价</div>
    </NavBar>
    <div class="evaluate-card">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
        v-if="true"
      >
        <div class="list list-margin" v-for="item in list" :key="item.id">
          <div class="user-info">
            <div class="head-img-box">
              <img :src="item.head_img" />
            </div>
            <div class="user-rate">
              <div>{{ item.nickname }}</div>
              <van-rate
                v-model="item.grade"
                :readonly="true"
                size="13"
                gutter="1"
              />
            </div>
          </div>
          <p class="evaluate-content">{{ item.content }}</p>
          <div class="evaluate-img-box">
            <div
              class="evaluate-img"
              v-for="(img, imgIndex) in item.goods_imgs"
              :key="imgIndex"
            >
              <img :src="img" @click="previewImg(item.goods_imgs, imgIndex)" />
            </div>
          </div>
        </div>
      </van-list>
      <BlankPage :blank="blankInfo" v-else></BlankPage>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import NavBar from "@/layout/nav-bar/nav-bar";
import BlankPage from "@/components/blank-page/blank-page";
import { getCommentList, getGoodsTag } from "@/api/goods.js";
export default {
  name: "evaluate-page",
  components: {
    NavBar,
    BlankPage,
  },
  data() {
    return {
      blankInfo: {
        imgUrl: require("@/assets/common/no_evaluation_icon_is_available.png"),
        text: "暂无评价",
      },
      evaluateList: [],
      finished: false,
      loading: false,
      list: [], //评价列表
      goodsId: this.$route.query.goodsId,
    };
  },
  mounted() {},
  methods: {
    onLoad() {
      this.getCommentList();
      this.loading = false;
    },
    previewImg(imgArr, index) {
      ImagePreview({
        images: imgArr,
        startPosition: index,
      });
    },
    async getCommentList() {
      const res = await getCommentList({
        goods_id: this.goodsId,
        page_num: 10,
      });
      this.list = res.data.items;
      console.log(res);
    },
  },
};
</script>

<style lang="less" scoped>
.page-back {
  position: relative;
  background-color: #fff;
  height: 100vh;
}
.nav-bar {
  background-color: #fff;
  border-bottom: 1px solid rgb(238, 238, 238);
}
.evaluate-card {
  padding: 12px;
}
.list {
  margin-bottom: 18px;
  .user-info {
    display: flex;
    align-items: center;
    .head-img-box {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .user-rate {
    font-size: 16px;
  }
  .evaluate-img-box {
    display: flex;
    justify-content: space-between;
    .evaluate-img {
      width: 116px;
      height: 116px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .evaluate-content {
    font-size: 14px;
    word-wrap: break-word;
  }
}
.van-list {
  padding-top: 52px;
}
</style>